<template>
	<div>
		<ProductCompareForm
			v-if="isHaveData"
			:is-loading="edit.isUpdating"
			:is-edit="true"
			:input-value="edit.data"
			@onSubmit="handleUpdateProductCompare"
			@onRemove="$refs['modal-remove'].open()"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteProductCompare.bind(null, $route.params.id)"
			description="Are you sure you want to delete this product compare ?"
			title="Remove this product compare?"
			is-confirm-required
			@onSuccess="$router.push('/product-compare')"
		/>
	</div>
</template>

<script>
import ProductCompareForm from '@/components/ProductCompareForm.vue';
import { mapActions, mapState } from 'vuex';
import { isEmptyObject } from '../assets/js/helpers';

export default {
	name: 'ProductCompareEdit',

	components: {
		ProductCompareForm,
	},
	data() {
		return {
			id: this.$route?.params?.id || null,
		};
	},
	computed: {
		...mapState('productCompares', {
			edit: 'edit',
		}),
		isHaveData() {
			return !isEmptyObject(this.edit.data);
		},
	},
	async created() {
		if (this.id) {
			await this.getProductCompareById(this.id);
		}
	},
	methods: {
		...mapActions({
			getProductCompareById: 'productCompares/getProductCompare',
			updateProductCompare: 'productCompares/updateProductCompare',
			deleteProductCompare: 'productCompares/deleteProductCompare',
		}),
		async handleUpdateProductCompare(payload) {
			await this.updateProductCompare({
				id: this.id,
				params: payload,
			});
			this.$router.push('/product-compare');
		},
	},
};
</script>
